<template lang="pug">
.info-box
  UilInfoCircle.info-box-icon
  .text-wrapper
    BodyText.body-text-700-md.info-box-title {{ title }}
    template(v-if="Array.isArray(text)")
      BodyText.body-text-400-sm.info-box-text(v-for="(line, index) in text" :key="index") {{ line }}
    template(v-else)
      BodyText.body-text-400-sm.info-box-text {{ text }}
</template>

<script>
  import { UilInfoCircle } from '@iconscout/vue-unicons';
  import BodyText from '../BodyText/BodyText.vue';

  export default {
    name: 'InfoBox',
    components: { BodyText, UilInfoCircle },
    props: {
      title: {
        type: String,
        required: true,
      },
      text: {
        type: [String, Array],
        required: true,
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import './infobox.sass'
</style>
